import { useMediaQuery, useTheme } from '@mui/material'
import { graphql, useStaticQuery } from 'gatsby'
import { HowItWorksContentPresentationalProps } from './howItWorksContentTypes'

import HowItWorksStep1Mobile from '../../../images/how-it-works-mobile-1.webp'
import HowItWorksStep2Mobile from '../../../images/how-it-works-mobile-2.webp'
import HowItWorksStep3Mobile from '../../../images/how-it-works-mobile-3.webp'
import HowItWorksStep4Mobile from '../../../images/how-it-works-mobile-4.webp'
import HowItWorksStep5Mobile from '../../../images/how-it-works-mobile-5.webp'
import HowItWorksStep6Mobile from '../../../images/how-it-works-mobile-6.webp'
import HowItWorksStep7Mobile from '../../../images/how-it-works-mobile-7.webp'

import HowItWorksStep1 from '../../../images/how-it-works-step-1.webp'
import HowItWorksStep2 from '../../../images/how-it-works-step-2.webp'
import HowItWorksStep3 from '../../../images/how-it-works-step-3.webp'
import HowItWorksStep4 from '../../../images/how-it-works-step-4.webp'
import HowItWorksStep5 from '../../../images/how-it-works-step-5.webp'
import HowItWorksStep6 from '../../../images/how-it-works-step-6.webp'
import HowItWorksStep7 from '../../../images/how-it-works-step-7.webp'

const useHowItWorksContent: () => HowItWorksContentPresentationalProps = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const { getDailyMetricsV1 } = useStaticQuery(graphql`
    query {
      getDailyMetricsV1 {
        dailyMetrics {
          state
          numProperties
          numOccupiedProperties
          totalSqft
          totalValuation
          totalOccupiedValuation
          totalMonthlyRentCents
          numPropertiesThisQuarter
        }
      }
    }
  `)

  const { dailyMetrics } = getDailyMetricsV1
  // defaults to portfolio, which will always be the last item in the array
  const dailyMetric = dailyMetrics[dailyMetrics.length - 1]
  const homeCount = dailyMetric.numProperties.toString()

  const steps = [
    {
      title: 'Get an offer & discuss your needs.',
      description:
        'Get a free, no-obligation offer on your properties, and discuss with one of our experienced Real Estate Advisors whether Flock is the right fit for you.',
      img: isMobile ? HowItWorksStep1Mobile : HowItWorksStep1,
    },
    {
      title: 'Perform due diligence.',
      description:
        'We’ll provide you resources, such as fund financials and reference calls with current investors, to vet us. In parallel, we’ll conduct a home inspection to lock in your offer price.',
      img: isMobile ? HowItWorksStep2Mobile : HowItWorksStep2,
    },
    {
      title: 'Sign the Contribution Agreement.',
      description:
        'Once you accept the offer, we’ll draft a Contribution Agreement (similar to a Purchase and Sale Agreement) for you to sign.',
      img: isMobile ? HowItWorksStep3Mobile : HowItWorksStep3,
    },
    {
      title: 'Complete onboarding to close.',
      description: `We’ll help you complete the online title and closing process, all from the comfort of your own home. Congratulations on joining our fund of ${homeCount} homes!`,
      img: isMobile ? HowItWorksStep4Mobile : HowItWorksStep4,
    },
    {
      title: 'Owning with Flock',
      description:
        'As a Flock Client, access your investor portal anytime, anywhere, to view fund financial details and fund activity. Passively own with full transparency.',
      img: isMobile ? HowItWorksStep5Mobile : HowItWorksStep5,
    },
    {
      title: 'Earning with Flock',
      description:
        'Every quarter, take cash flow or leave it in to build your nest egg. Within the portal, view your financial performance and detailed tax performance.',
      img: isMobile ? HowItWorksStep6Mobile : HowItWorksStep6,
    },
    {
      title: 'Tax & Estate Planning with Flock',
      description:
        'Flexibly allocate your Flock investment to your heirs (who receive a step-up in tax basis upon passing) or redeem them for cash to spread out your tax burden.',
      img: isMobile ? HowItWorksStep7Mobile : HowItWorksStep7,
    },
  ]

  return {
    isMobile,
    isTablet,

    steps,
  }
}

export default useHowItWorksContent
